import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Slider, TextField, Button, Card, CardContent } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';

function TranscriptAndAudio({ simulation }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const audioRef = useRef(null);
    const transcriptRef = useRef(null);
    const entryRefs = useRef({});

    useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
        audio.addEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
        audio.addEventListener('loadedmetadata', () => setDuration(audio.duration));
        return () => {
            audio.removeEventListener('timeupdate', () => setCurrentTime(audio.currentTime));
            audio.removeEventListener('loadedmetadata', () => setDuration(audio.duration));
        }
    }
}, []);

    const togglePlayPause = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleSliderChange = (event, newValue) => {
        if (audioRef.current) {
            audioRef.current.currentTime = newValue;
            setCurrentTime(newValue);
        }
    };

    const handleFastForward = () => {
        if (audioRef.current) {
            audioRef.current.currentTime += 10;
        }
    };
    
    const handleRewind = () => {
        if (audioRef.current) {
            audioRef.current.currentTime -= 10;
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleDownloadTranscript = () => {
        const element = document.createElement("a");
        const file = new Blob([transcriptData.map(entry => `${entry.speaker}: ${entry.text}`).join('\n')], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = "transcript.txt";
        document.body.appendChild(element);
        element.click();
    };

    const handleDownloadAudio = async () => {
        if (recordingUrl) {
            try {
                const response = await fetch(recordingUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'call_recording.mp3';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading audio:', error);
            }
        }
    };

    const highlightSearchTerm = (text, searchTerm) => {
        if (!searchTerm) return { result: text, firstMatchIndex: -1 };
        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
        let firstMatchIndex = -1;
        const result = parts.map((part, index) => {
            if (part.toLowerCase() === searchTerm.toLowerCase() && firstMatchIndex === -1) {
                firstMatchIndex = index;
                return <mark key={index} ref={el => { if (el) entryRefs.current[index] = el; }}>{part}</mark>;
            }
            return part;
        });
        return { result, firstMatchIndex };
    };

    useEffect(() => {
        if (searchTerm) {
            const firstMatchRef = Object.values(entryRefs.current).find(ref => ref);
            if (firstMatchRef) {
                firstMatchRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [searchTerm]);

    if (!simulation) {
        return <Typography>No simulation data available.</Typography>;
    }

    const { conversation, recordingUrl } = simulation;

    const transcriptData = conversation ? conversation.map(msg => ({
        speaker: msg.role !== 'Prospect' ? 'You' : 'Prospect',
        text: msg.message || '',
        time: msg.time
    })) : [];

    return (
        <Box>
            <Card elevation={3} sx={{ mb: 3 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Call Recording</Typography>
                    {recordingUrl ? (
                        <Box>
                            <audio ref={audioRef} src={recordingUrl} />
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <IconButton onClick={handleRewind}>
                                    <FastRewindIcon />
                                </IconButton>
                                <IconButton onClick={togglePlayPause}>
                                    {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}           
                                </IconButton>
                                <IconButton onClick={handleFastForward}>
                                    <FastForwardIcon />
                                </IconButton>
                                <Typography variant="body2" sx={{ ml: 2 }}>
                                    {formatTime(currentTime)} / {formatTime(duration)}
                                </Typography>
                            </Box>
                            <Slider
                                value={currentTime}
                                max={duration}
                                onChange={handleSliderChange}
                                aria-labelledby="continuous-slider"
                                aria-label="audio progress"
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    startIcon={<DownloadIcon />}
                                    onClick={handleDownloadAudio}
                                >
                                    Download Audio
                                </Button>
                            </Box>
                        </Box>
                    ) : (
                        <Typography>No recording available.</Typography>
                    )}
                </CardContent>
            </Card>
            <Card elevation={3}>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                        <Typography variant="h6" >Call Transcript</Typography>
                        <Box>
                            <TextField
                                size="small"
                                placeholder="Search transcript"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                InputProps={{
                                    startAdornment: <SearchIcon color="action" />
                                }}
                                sx={{ mr: 2}}
                            />
                            <Button
                                variant="outlined"
                                startIcon={<DownloadIcon />}
                                onClick={handleDownloadTranscript}
                            >
                                Download Transcript
                            </Button>
                        </Box>
                    </Box>
                    {transcriptData.length > 0 ? (
                        <Box ref={transcriptRef} sx={{ overflowY: 'auto', bgcolor: '#f5f5f5', p: 2, borderRadius: 1, height: '300px', maxHeight: '50vh' }}>
                            {transcriptData.map((entry, index) => {
                                const { result, firstMatchIndex } = highlightSearchTerm(entry.text, searchTerm);
                                return (
                                    <Box
                                        key={index}
                                        ref={el => { if (el && firstMatchIndex !== -1) entryRefs.current[index] = el; }}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: entry.speaker !== 'Prospect' ? 'flex-end' : 'flex-start',
                                            mb: 2
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                maxWidth: '70%',
                                                backgroundColor: entry.speaker !== 'Prospect' ? '#DCF8C6' : '#E0E0E0',
                                                borderRadius: '10px',
                                                p: 1,
                                            }}
                                        >
                                            <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                                                <strong>{entry.speaker}: </strong>
                                                <span style={{ marginLeft: '4px'}}>{result}</span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        <Typography>No transcript available.</Typography>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}

export default TranscriptAndAudio;
