import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

function DetailedAnalysisCard({ category, explanation, examples, tips, alternativeMethods, isStrength }) {
    const [expanded, setExpanded] = useState(false);

    return (
        <Card sx={{ mb: 2, backgroundColor: isStrength ? '#e8f5e9' : '#ffebee' }}>
            <CardContent sx={{ minHeight: 125 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center">
                        {isStrength ?
                            <TrendingUpIcon sx={{ color: 'success.main', mr: 1 }} /> :
                            <TrendingDownIcon sx={{ color: 'error.main', mr: 1 }} />    
                        }
                        <Typography variant="h6">{category}</Typography>
                    </Box>
                    <IconButton
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Box>
                <Typography variant="body2" sx={{ mt: 1 }}>{explanation}</Typography>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="subtitle2">Examples:</Typography>
                        <ul>
                            {examples.map((example, index) => (
                                <li key={index}><Typography variant="body2">{example}</Typography></li>
                            ))}
                        </ul>
                        <Typography variant="subtitle2" sx={{ mt: 1 }}>Tips:</Typography>
                        <ul>
                            {tips.map((tip, index) => (
                                <li key={index}><Typography variant="body2">{tip}</Typography></li>
                            ))}
                        </ul>
                        <Typography variant="subtitle2" sx={{ mt: 1 }}>Alternative Strategies:</Typography>
                        <ul>
                            {alternativeMethods.map((method, index) => (
                                <li key={index}><Typography variant="body2">{method}</Typography></li>
                            ))}
                        </ul>
                    </Box>
                </Collapse>
            </CardContent>
        </Card>
    );
}

function DetailedAnalysis({ detailedAnalysis }) {
    if (!detailedAnalysis) {
        return <Typography>No detailed analysis available</Typography>
    }

    return (
        <Box>
            <Typography variant="h5" gutterBottom>Detailed Analysis</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>Strengths</Typography>
                    {detailedAnalysis.topCategories.map((category, index) => (
                        <DetailedAnalysisCard key={index} {...category} isStrength={true} />
                    ))}
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>Areas for Improvement</Typography>
                    {detailedAnalysis.bottomCategories.map((category, index) => (
                        <DetailedAnalysisCard key={index} {...category} isStrength={false} />
                    ))}
                </Grid>
            </Grid>
        </Box>
    );
}

export default DetailedAnalysis;