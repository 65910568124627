import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const createCheckoutSession = async (priceId, userId, isSubscription) => {
    const stripe = await stripePromise;

    try {
        const response = await fetch(`${apiBaseUrl}/api/stripe/create-checkout-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'true',
            },
            body: JSON.stringify({ priceId, userId, isSubscription }),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const session = await response.json();

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            throw new Error(result.error.message);
        }
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
};