import React from 'react';
import { Box, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import HistoryIcon from '@mui/icons-material/History';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmailIcon from '@mui/icons-material/Email';
import DashboardIcon from '@mui/icons-material/Dashboard';

const ColoredBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#E6ECFE',
    color: '#2853eb',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderRadius: theme.shape.borderRadius,
    },
}));

function HelpModal({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                Coachable AI Help Guide
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <ColoredBox>
                    <Typography variant="h6" gutterBottom sx={{ color: '#2853eb', fontWeight: 'bold' }}>Steps to Start a Simulations:</Typography>
                    <List>
                        {[
                            "Click 'Start New Simulation' on the dashboard",
                            "Choose simulation type (Cold Call or Discovery Meeting)",
                            "Review and confirm your product summary",
                            "Start your simulation and engage in the conversation with your AI prospect",
                            "Review your scorecard and detailed analysis for strengths and areas of improvement."
                        ].map((step, index) => (
                            <StyledListItem key={index}>
                                <ListItemIcon>
                                    <PlayArrowIcon sx={{ color: 'primary.main' }} />
                                </ListItemIcon>
                                <ListItemText primary={`${index + 1}. ${step}`} sx={{ color: 'primary.main' }} />
                            </StyledListItem>
                        ))}
                    </List>
                </ColoredBox>

                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    Key Components:
                </Typography>
                <List>
                    {[
                        { icon: <AssessmentIcon color="primary" />, primary: "Recent Simulations", secondary: "View your most recent simulation results and scores" },
                        { icon: <DashboardIcon color="primary" />, primary: "Performance Dashboard", secondary: "Track your overall performance and improvement over time" },
                        { icon: <HistoryIcon color="primary" />, primary: "Simulation History", secondary: "Access all of your past simulations to review previous performance and history" }
                    ].map((item, index) => (
                        <StyledListItem key={index}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.primary} secondary={item.secondary} />
                        </StyledListItem>
                    ))}
                </List>

                <Divider sx={{ my: 2 }} />

                <Typography variant="h6" gutterBottom sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    Need More Help?
                </Typography>
                <Typography paragraph>
                    If you have any questions, issues, or feedback, please hesitate to reach out to our team at:
                </Typography>
                <Typography paragraph display="flex" alignItems="center" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
                    <EmailIcon sx={{ mr: 1, color: '#2853eb' }} />
                    support@joincoachable.ai
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="secondary">Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default HelpModal;