import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, CircularProgress, Typography, Fade, Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

function LoadingScreen() {
    const [tip, setTip] = useState('');
    const [tipIndex, setTipIndex] = useState(0);

    const tips = useMemo(() => [
        "Top salespeople master the art of listening and understanding their clients' needs.",
        "Consistent practice can boost your win rates by up to 30%.",
        "Thorough preparation for each call sets the stage for success.",
        "Empathy and genuine interest in clients' needs distinguish top performers.",
        "Practice makes perfect - even in sales!",
        "Great sales reps ask insightful questions and actively listen to responses.",
        "Building strong rapport with clients is essential for successful sales.",
        "TTop sales reps focus on solving problems, not just selling products.",
        "Confidence and enthusiasm can positively influence your sales conversations.",
        "A well-timed and thoughtful follow-up can turn prospects into customers."
    ], []);

    const rotateTip = useCallback(() => {
        setTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
    }, [tips]);

    useEffect(() => {
        const intervalId = setInterval(rotateTip, 5000);
        return () => clearInterval(intervalId);
    }, [rotateTip]);

    useEffect(() => {
        setTip(tips[tipIndex]);
    }, [tips, tipIndex]);

   return (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        textAlign="center"
        padding={3}
    >
        <Box position="relative" display="inline-flex">
            <CircularProgress size={100} thickness={3} />
            <Box
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                top={0}
                left={0}
                bottom={0}
                right={0}
            >
                <Avatar sx={{ bgcolor: 'primary.main', width: 60, height: 60 }}>
                    <PersonIcon sx={{ fontSize: 40 }} />
                </Avatar>
            </Box>
        </Box>
        <Fade in={true} timeout={1000}>
            <Typography variant="h4" mt={4} mb={2}>
                Generating Your Persona!
            </Typography>
        </Fade>
        <Fade key={tipIndex} in={true} timeout={500}>
            <Typography variant="body1" mt={4} fontStyle="italic" maxWidth="400px">
                {tip}
            </Typography>
        </Fade>
    </Box>
   );
}

export default LoadingScreen;