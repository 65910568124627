import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, Card, CardContent, Button, Stepper, Step, StepLabel } from '@mui/material';
import Header from '../Dashboard/Header';
import NavigationSidebar from './NavigationSidebar';
import Scorecard from './Scorecard';
import ScoreCardHeader from './ScoreCardHeader';
import PersonaInfo from './PersonaInfo';
import DetailedAnalysis from './DetailedAnalysis';
import TranscriptAndAudio from './TranscriptAndAudio';
import useCallReviewData from '../../hooks/useCallReviewData';
import analytics from '../../segmentAnalytics';
import { useAuthInfo } from '@propelauth/react';

const steps = ['Scorecard', 'Detailed Analysis', 'Persona Info', 'Transcript & Audio'];

function CallReviewPage() {
    const { simulationId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const scorecardViewedRef = useRef(false);
    const [activeSection, setActiveSection] = useState(steps[0]);

    const authInfo = useAuthInfo();
    
    const  memoizedInitialData = useMemo(() => {
        return location.state?.scorecard && location.state?.persona
            ? { scorecard: location.state.scorecard, persona: location.state.persona }
            : null;
    }, [location.state]);

    const { data, isLoading, error } = useCallReviewData(simulationId, memoizedInitialData);

    const trackSectionChange = useCallback((newSection) => {
        analytics.track('Call Review Section Viewed', {
            simulationId,
            callReviewSection: newSection,
            simulationType: data.scorecard?.simulationType
        });
    }, [simulationId, data.scorecard]);

    const canShowScorecard = data.scorecard && data.scorecard.initialAnalysis;
    const canShowDetailedAnalysis = data.scorecard && data.scorecard.detailedAnalysis;
    const canShowTranscript = data.simulation && (data.simulation.conversation || data.simulation.recordingUrl);

    useEffect(() => {
        if (activeSection === 'Detailed Analysis' && !canShowDetailedAnalysis) {
            setActiveSection('Scorecard');
        } else if (activeSection === 'Transcript & Audio' && !canShowTranscript) {
            setActiveSection('Scorecard');
        }
    }, [activeSection, canShowDetailedAnalysis, canShowTranscript]);

    useEffect(() => {
        let isMounted = true;
        if (data.scorecard && activeSection === 'Scorecard' && !scorecardViewedRef.current) {
            const trackingPromise = analytics.track('Scorecard Viewed', {
                simulationId,
                simulationType: data.scorecard?.simulationType,
                overallScore: data.scorecard?.overallScore
            });

            trackingPromise.then(() => {
                if (isMounted) {
                    scorecardViewedRef.current = true;
                }
            }).catch(error => {
                if (isMounted) {
                    console.error('Failed to track Scorecard Viewed event', error);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [data.scorecard, activeSection, simulationId]);

    const handleNext = useCallback(() => {
        const currentIndex = steps.indexOf(activeSection);
        if (currentIndex < steps.length - 1) {
            const newSection = steps[currentIndex + 1];
            setActiveSection(newSection);
            trackSectionChange(newSection);
        }
    }, [activeSection, trackSectionChange]);

    const handlePrevious =useCallback(() => {
        const currentIndex = steps.indexOf(activeSection);
        if (currentIndex > 0) {
            const newSection = steps[currentIndex - 1];
            setActiveSection(newSection);
            trackSectionChange(newSection);
        }
    }, [activeSection, trackSectionChange]);

    const handleReturnToDashboard = useCallback(() => {
        analytics.track('Call Review Exited', {
            simulationId,
            exitDestination: 'Dashboard'
        });
        navigate('/');
    }, [navigate, simulationId]);

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    const renderContent = () => {
        switch (activeSection) {
            case 'Persona Info':
                return data.persona ? <PersonaInfo persona={data.persona} /> : <CircularProgress />;
            case 'Scorecard':
                return canShowScorecard ? <Scorecard scorecard={data.scorecard} /> : <CircularProgress />;
            case 'Detailed Analysis':
                return canShowDetailedAnalysis ? <DetailedAnalysis detailedAnalysis={data.scorecard.detailedAnalysis} /> : <CircularProgress />;
            case 'Transcript & Audio':
                return canShowTranscript ? <TranscriptAndAudio simulation={data.simulation} /> : <CircularProgress />;
            default:
                return canShowScorecard ? <Scorecard scorecard={data.scorecard} /> : <CircularProgress />;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header />
            <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
                <Box sx={{ width: '240px', flexShrink: 0, overflow: 'auto' }}>
                    <NavigationSidebar
                        activeSection={activeSection}
                        setActiveSection={(newSection) => {
                            setActiveSection(newSection);
                            trackSectionChange(newSection);
                        }}
                        canShowDetailedAnalysis={canShowDetailedAnalysis}
                        canShowTranscript={canShowTranscript}
                    />
                </Box>
                <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden', p: 3 }}>
                    <Stepper activeStep={steps.indexOf(activeSection)} sx={{ mb: 3 }}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {data.scorecard && (
                        <ScoreCardHeader
                            date={new Date(data.scorecard.createdAt).toLocaleDateString()}
                            simulationType={data.scorecard.simulationType === 'cold-call' ? 'Cold Call' : 'Discovery Meeting'}
                            sectionScores={data.scorecard.initialAnalysis.sections}
                            summary={data.scorecard.overallSummary}
                            userName={authInfo.user.firstName}
                        />
                    )}
                    <Card sx={{ mt: 2, flexGrow: 1, overflow: 'auto' }}>
                        <CardContent>
                            {isLoading && !data.scorecard ? <CircularProgress /> : renderContent()}
                        </CardContent>
                    </Card>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 0, borderTop: 1, borderColor: 'divider', pt: 2 }}>
                        <Button onClick={handlePrevious} disabled={steps.indexOf(activeSection) === 0}>
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReturnToDashboard}
                        >
                            Return to Dashboard
                        </Button>
                        <Button onClick={handleNext} disabled={steps.indexOf(activeSection) === steps.length - 1}>
                            Next
                        </Button>
                    </Box>
                </Box> 
            </Box>
        </Box> 
    );
}

export default CallReviewPage;