import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Box, CircularProgress } from '@mui/material';

function CountdownDialog({ open, onClose }) {
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        if (open && countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [open, countdown]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle id="countdown-dialog-title" sx={{ textAlign: 'center' }}>
                Get Ready in...
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 2 }}>
                    <CircularProgress size={60} thickness={6} variant="determinate" value={(3 - countdown) / 3 * 100} />
                    <Typography variant="h4" sx={{ position: 'absolute' }}>
                        {countdown}
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default CountdownDialog;