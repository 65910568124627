import React, { useState, useEffect, useRef } from 'react';
import { Typography, Avatar, Box, Button, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';

const pulse = keyframes`
    0% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
    }
    70% {
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
    }
    100% {
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
    }
`;

const PersonaCard = styled(({ isAISpeaking, ...other }) => <Box {...other} />)(({ theme, isAISpeaking }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
    animation: isAISpeaking ? `${pulse} 1.5s infinite` : 'none',
    transition: 'all 0.3s ease',
    border: isAISpeaking ? `4px solid ${theme.palette.primary.main}` : `4px solid transparent`,
}));

const TranscriptEntry = styled(({ isUserMessage, ...other }) => <Box {...other} />)(({ theme, isUserMessage }) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: isUserMessage ? 'flex-end' : 'flex-start',
}));

const MessageBubble = styled(({ isUserMessage, ...other }) => <Box {...other} />)(({ theme, isUserMessage }) => ({
    maxWidth: '70%',
    padding: theme.spacing(1, 2),
    backgroundColor: isUserMessage ? theme.palette.primary.light : theme.palette.grey[200],
    color: isUserMessage ? theme.palette.primary.contrastText : theme.palette.text.primary,
    borderRadius: theme.spacing(2),
    borderTopLeftRadius: isUserMessage ? theme.spacing(2) : 0,
    borderTopRightRadius: isUserMessage ? 0 : theme.spacing(2),
}));

function MainConversationArea({ persona, isAISpeaking, transcriptData }) {
    const [showTranscript, setShowTranscript] = useState(false);
    const transcriptRef = useRef(null);

    useEffect(() => {
        if (transcriptRef.current) {
            transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
        }
    }, [transcriptData]);

    return (
        <>
            <PersonaCard isAISpeaking={isAISpeaking}>
                <Avatar src={persona.imageUrl} sx={{ width: 100, height: 100, mr: 2 }} />
                    <Box>
                        <Typography variant="h5">{persona.name}</Typography>
                        <Typography variant="subtitle1">{persona.title}</Typography>
                    </Box>
            </PersonaCard>

            <Button
                onClick={() => setShowTranscript(!showTranscript)}
                variant="outlined"
                sx={{ mb:2 }}
            >
                {showTranscript ? 'Hide Transcript' : 'Show Transcript'}
            </Button>

            {showTranscript && (
                <Box
                    ref={transcriptRef}
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        bgcolor: 'background.default',
                        p: 2,
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    {transcriptData.map((entry, index) => (
                        <TranscriptEntry key={index} isUserMessage={entry.speaker === 'You'}>
                            <MessageBubble isUserMessage={entry.speaker === 'You'}>
                                <Typography variant="body2" fontWeight="bold">
                                    {entry.speaker}:
                                </Typography>
                                <Typography variant="body1">{entry.text}</Typography>
                            </MessageBubble>
                        </TranscriptEntry>
                    ))}
                </Box>
            )}
        </>
    );
}

export default MainConversationArea;