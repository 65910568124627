import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';

function ProfileSummary({ productSummary, onEditProfile, isLoading }) {
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                    Profile
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onEditProfile}
                    sx={{ mb: 2, width: '100%' }}
                    disabled={isLoading}
                >
                    {isLoading ? 'Loading...' : (productSummary?.summary ? 'Edit Product Summary' : 'Complete Your Profile')}
                </Button>
                {productSummary && (
                    <Typography variant="body2" color="text.secondary">
                        {productSummary.summary}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}

export default ProfileSummary;