import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Button, Box, Stepper, Step, StepLabel, StepContent, Paper, CircularProgress } from '@mui/material';
import { useAuthInfo } from '@propelauth/react'
import SimulationTypeModal from './SimulationTypeModal';
import PersonaGenerationMethodModal from './PersonaGenerationMethodModal';
import ProductSummaryReviewModal from './ProductSummaryReviewModal';
import PerformanceOverview from './PerformanceOverview';
import DifficultySelectionModal from './DifficultySelectionModal';
import CreditCheckModal from './CreditCheckModal';
import analytics from '../../segmentAnalytics'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AssessmentIcon from '@mui/icons-material/Assessment';

function MainContent({ onPersonaGenerationStateChange, dashboardData, onEditProfile, productSummary, onSaveSummary, isUpdating, userData, onOpenUpgradeModal }) {
    const authInfo = useAuthInfo();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [simulationTypeModalOpen, setSimulationTypeModalOpen] = useState(false);
    const [personaMethodModalOpen, setPersonaMethodModalOpen] = useState(false);
    const [selectedSimulationType, setSelectedSimulationType] = useState(null);
    const [productSummaryReviewModalOpen, setProductSummaryReviewModalOpen] = useState(false);
    const [selectedPersonaMethod, setSelectedPersonaMethod] = useState(null);
    const [difficultyModalOpen, setDifficultyModalOpen] = useState(false);
    const [selectedDifficulty, setSelectedDifficulty] = useState(null);
    const [creditCheckModalOpen, setCreditCheckModalOpen] = useState(false);
    const [isNewUser, setIsNewUser] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (dashboardData?.completedSimulations?.length > 0) {
            setIsNewUser(false);
            setActiveStep(2);
        } else if (productSummary?.summary) {
            setActiveStep(1);
        } else {
            setIsNewUser(true);
            setActiveStep(0);
        }
    }, [dashboardData, productSummary]);
    
    const handleStartSimulation = () => {
        if (userData && (userData.credits !== undefined || userData.plan)) {
            if (userData.plan === 'Unlimited' || (userData.credits && userData.credits > 0)) {
                analytics.track('New Simulation Flow Started', {
                    startSource: 'Dashboard'
                });
                setSimulationTypeModalOpen(true);
            } else {
                analytics.track('Out of Credits Warning Viewed');
                setCreditCheckModalOpen(true);
            }
        } else {
            analytics.track('New Simulation Flow Started', {
                startSource: 'Dashboard'
            });
            setSimulationTypeModalOpen(true);
        }
    };

    const handleSimulationTypeSelection = (type) => {
        analytics.track('Simulation Type Selected', {
            simulationType: type,
        });
        setSelectedSimulationType(type);
        setSimulationTypeModalOpen(false);
        setDifficultyModalOpen(true);
    };

    const handleDifficultySelection = (difficulty) => {
        analytics.track('Difficulty Level Selected', {
            difficultyLevel: difficulty,
        });
        setSelectedDifficulty(difficulty);
        setDifficultyModalOpen(false);
        setPersonaMethodModalOpen(true);
    }

    const handleBackToSimulationType = () => {
        setDifficultyModalOpen(false);
        setSimulationTypeModalOpen(true);
    };

    const handlePersonaMethodSelection = (method) => {
        analytics.track('Persona Generation Method Selected', {
            personaGenerationMethod: method,
        });
        setSelectedPersonaMethod(method);
        setPersonaMethodModalOpen(false);
        setProductSummaryReviewModalOpen(true);
    };

    const handleBackToPersonaMethod = () => {
        setProductSummaryReviewModalOpen(false);
        setPersonaMethodModalOpen(true);
    };

    const handleProductSummaryReviewConfirm = async (summaryData) => {
        analytics.track('Persona Generation Started');
        onPersonaGenerationStateChange(true);
        await onSaveSummary(summaryData);
        try {
            const simulationGender = Math.random() < 0.5 ? 'male' : 'female';

            const response = await fetch(`${apiBaseUrl}/api/persona/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify({
                    summary: summaryData.summary,
                    url: summaryData.url,
                    propelAuthId: authInfo.user.userId,
                    simulationGender: simulationGender,
                    simulationType: selectedSimulationType,
                    difficulty: selectedDifficulty,
                    personaMethod: selectedPersonaMethod,
                    userEmail: authInfo.user.email,
                    personaType: 'initial',
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to generate persona');
            }
            const data = await response.json();

            navigate('/persona-display', { state: {persona: data, simulationType: selectedSimulationType, difficulty: selectedDifficulty, summary: summaryData.summary, userPlan: userData?.plan || 'Trial' } });
        } catch (error) {
            console.error('Error generating persona:', error);
        } finally {
            onPersonaGenerationStateChange(false);
        }
    };

    if (isUpdating) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100">
                <CircularProgress />
            </Box>
        );
    }

    const steps = [
        {
            label: 'Complete Profile',
            description: `Set up your product summary to tailor your simulations.`,
            icon: <AccountCircleIcon />,
            action: onEditProfile
        },
        {
            label: 'Start First Simulation',
            description: `Begin your journey with a cold call or discovery meeting simulation.`,
            icon: <PlayArrowIcon />,
            action: handleStartSimulation
        },
        {
            label: 'Review Performance',
            description: `Analyze your scorecard and improve your skills.`,
            icon: <AssessmentIcon />,
            action: () => {
                if (dashboardData?.completedSimulations?.length > 0) {
                    const latestSimulation = dashboardData.completedSimulations[0];
                    navigate(`/call-review/${latestSimulation.simulationId}`);
                }
            }
        },
    ];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4" component="div">
                                Welcome {authInfo.user.firstName}!
                            </Typography>
                            {!isNewUser && (
                                <Button 
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={handleStartSimulation}
                                >
                                    Start New Simulation
                                </Button>
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            {isNewUser ? (
                <Grid item xs={12}>
                    <Paper elevation={3} sx={{ p: 3, bgcolor: 'background.default' }}>
                        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                            Let's get you started with Coachable AI
                        </Typography>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step.label}>
                                    <StepLabel
                                        optional={
                                            index === 2 ? (
                                                <Typography variant="caption">Last Step</Typography>
                                            ) : null
                                        }
                                        icon={step.icon}
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent>
                                        <Typography>{step.description}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                <Button
                                                    variant="contained"
                                                    onClick={step.action}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    {index === steps.length -1 ? 'Finish' : 'Continue'}
                                                </Button>
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={() => setActiveStep((prevActiveStep) => prevActiveStep -1)}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Paper>
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <PerformanceOverview data={dashboardData?.completedSimulations} />
                </Grid>
            )}
            <CreditCheckModal
                open={creditCheckModalOpen}
                onClose={() => setCreditCheckModalOpen(false)}
                onUpgrade={onOpenUpgradeModal}
            />
            <SimulationTypeModal
                open={simulationTypeModalOpen}
                onClose={() => setSimulationTypeModalOpen(false)}
                onSelect={handleSimulationTypeSelection}
            />
            <DifficultySelectionModal
                open={difficultyModalOpen}
                onClose={() => setDifficultyModalOpen(false)}
                onSelect={handleDifficultySelection}
                onBack={handleBackToSimulationType}
            />
            <PersonaGenerationMethodModal
                open={personaMethodModalOpen}
                onClose={() => setPersonaMethodModalOpen(false)}
                onBack={() => {
                    setPersonaMethodModalOpen(false);
                    setDifficultyModalOpen(true);
                }}
                onSelect={handlePersonaMethodSelection}
                simulationType={selectedSimulationType}
            />
            <ProductSummaryReviewModal
                open={productSummaryReviewModalOpen}
                onClose={() => setProductSummaryReviewModalOpen(false)}
                onConfirm={handleProductSummaryReviewConfirm}
                productSummary={productSummary}
                onSaveSummary={onSaveSummary}
                onBack={handleBackToPersonaMethod}
                isLoading={isUpdating}
            />
        </Grid>
    );
}

export default MainContent;