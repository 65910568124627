import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { RequiredAuthProvider, RedirectToLogin, useAuthInfo } from '@propelauth/react';
import App from './App';
import analytics from './segmentAnalytics';
import * as Sentry from "@sentry/react";

const originalConsoleError = console.error;
console.error = (...args) => {
  if (args[0] instanceof Error) {
    Sentry.captureException(args[0]);
  } else {
  const error = new Error(args.join(' '));
  error.stack = error.stack.split('\n').slice(1).join('\n');
  Sentry.captureException(error);
  }
  originalConsoleError(...args);
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  traceSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "https://app.joincoachable.ai", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

window.onerror = (message, source, lineno, colno, error) => {
  Sentry.captureException(error || new Error(message));
};

window.onunhandledrejection = (event) => {
  Sentry.captureException(event.reason);
};

const ErrorFallback = ({error}) => {
  return (
    <div>
      <h1>Oops! Something went wrong. We've notified the team.</h1>
      <p>Error: {error.message}</p>
    </div>
  );
};

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  }
};

const debouncedSendAnalyticsEvents = debounce((user) => {
  if (user) {
    analytics.identify(user.userId, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      companyName: user.properties.companyName,
    });

    if (user.properties.companyName) {
      analytics.group(user.properties.companyName, {
        companyName: user.properties.companyName,
      });
    }
  }
}, 1000);

function AuthWrapper() {
  const authInfo = useAuthInfo();
  const prevUserIdRef = useRef(null);

  useEffect(() => {
    if (authInfo.isLoggedIn && authInfo.user.userId !== prevUserIdRef.current) {
      prevUserIdRef.current = authInfo.user.userId;
      debouncedSendAnalyticsEvents(authInfo.user);
    } else if (!authInfo.isLoggedIn && prevUserIdRef.current !== null) {
      prevUserIdRef.current = null;
      analytics.reset();
    }
  }, [authInfo]);

  return <App />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
    <RequiredAuthProvider 
      authUrl={process.env.REACT_APP_AUTH_URL}
      displayIfLoggedOut={<RedirectToLogin />}
      >
      <AuthWrapper />
    </RequiredAuthProvider>
  </Sentry.ErrorBoundary>
);
