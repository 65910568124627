import React from 'react';
import { Paper, Typography, Divider, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

function PersonaInfoPanel({ persona }) {
    return (
        <Paper elevation={3} sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
                Prospect Details
            </Typography>
            <Divider sx={{ my: 2}} />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <PersonIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="h6">
                    Bio:
                </Typography>
            </Box>
            <Typography variant="body2" paragraph sx={{ mt: 1 }}>
                {persona.personaBio}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <BusinessIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="h6">
                    Company Details:
                </Typography>
            </Box>
            <Typography variant="body2" paragraph sx={{ mt: 1 }}>
                {persona.companyDetails}
            </Typography>
        </Paper>
    );
}

export default PersonaInfoPanel;