import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, Fade } from '@mui/material'
import { styled } from '@mui/material/styles';

const SmoothLinearProgress = styled(LinearProgress)({
    '& .MuiLinearProgress-bar': {
        transition: 'transform 0.5s ease',
    },
});

const analysisSteps = [
    { step: "Great job completing your call!", progress: 0 },
    { step: "Analyzing conversation...", progress: 14 },
    { step: "Generating overall score...", progress: 28 },
    { step: "Identifying key moments...", progress: 42 },
    { step: "Evaluating objection handling...", progress: 56 },
    { step: "Assessing communication style...", progress: 70 },
    { step: "Compiling feedback and insights...", progress: 84 },
    { step: "Finalizing your personalized report...", progress: 98 },
];

function CallEndingScreen() {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    useEffect(() => {
        const stepInterval = setInterval(() => {
            setCurrentStepIndex((prevIndex) => {
                if (prevIndex === analysisSteps.length - 1) {
                    clearInterval(stepInterval);
                    return prevIndex;
                }
                return prevIndex + 1;
            });
        }, 2000);

        return () => {
            clearInterval(stepInterval);
        };
    }, []);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
            padding={3}
        >
            <Typography variant="h4" gutterBottom>
                Preparing Your Call Report
            </Typography>
            <Box width="80%" marginY={4}>
                <SmoothLinearProgress variant="determinate" value={analysisSteps[currentStepIndex].progress} />
            </Box>
            <Fade key={currentStepIndex} in={true} timeout={1000}>
                <Typography variant="h6" textAlign="center" style={{ marginBottom: '2rem', minHeight: '3em', marginTop: '2rem' }}>
                    {analysisSteps[currentStepIndex].step}
                </Typography>
            </Fade>
        </Box>
    );
}

export default CallEndingScreen;