import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TableSortLabel, Select, MenuItem, FormControl, InputLabel, Card, CardContent, Grid, Box, CircularProgress } from '@mui/material';
import { useAuthInfo } from '@propelauth/react';
import Header from './Header';
import { styled } from '@mui/material/styles';
import AssessmentIcon from '@mui/icons-material/Assessment';

function SimulationHistory() {
    const location = useLocation();
    const [simulations, setSimulations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('date');
    const [order, setOrder] = useState('desc');
    const [filter, setFilter] = useState('all');
    const navigate = useNavigate();
    const { user } = useAuthInfo();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleRowClick = (simulationId) => {
        navigate(`/call-review/${simulationId}`);
    };

    const fetchDashboardData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/database/dashboard-data/${user.userId}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                }
            });
            if (!response.ok) throw new Error('Failed to fetch dashboard data');
            const data = await response.json();
            setSimulations(data.completedSimulations || []);
        } catch (error) {
            console.error('Error fetching simulations:', error);
            setSimulations([]);
        } finally {
            setIsLoading(false);
        }
    }, [user.userId, apiBaseUrl]);

    useEffect(() => {
        if (location.state?.simulations) {
            setSimulations(location.state.simulations);
            setIsLoading(false);
        } else {
            fetchDashboardData();
        }
    }, [location.state, fetchDashboardData])

    const checkAndFetchData = useCallback(() => {
        if (simulations.length === 0) {
            fetchDashboardData();
        }
    }, [fetchDashboardData, simulations.length]);

    useEffect(() => {
        checkAndFetchData();
    }, [checkAndFetchData]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
        setPage(0);
    };

    const formatSimulationType = (type) => {
        switch (type) {
            case 'cold-call':
                return 'Cold Call';
            case 'discovery-meeting':
                return 'Discovery Meeting';
            default:
                return type;
        }
    };

    const filteredSimulations = simulations.filter(simulation =>
        filter === 'all' || simulation.simulationType === filter
    );

    const sortedSimulations = filteredSimulations.sort((a, b) => {
        if (orderBy === 'date') {
            return order === 'asc' ? new Date(a.createdAt) - new Date(b.createdAt) : new Date(b.createdAt) - new Date(a.createdAt);
        }
        if (orderBy === 'score') {
            return order === 'asc' ? a.scorecard.overallScore - b.scorecard.overallScore : b.scorecard.overallScore - a.scorecard.overallScore;
        }
        return 0;
    });

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& .MuiTableSortLabel-root': {
            color: theme.palette.common.white,
        },
        '& .MuiTableSortLabel-root.Mui-active': {
            color: theme.palette.common.white,
        },
        '& .MuiTableSortLabel-icon': {
            color: theme.palette.common.white + ' !important',
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        },
    }));

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Card elevation={3} sx={{ mb: 4, p: 3 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <AssessmentIcon fontSize="large" color="primary" sx={{ verticalAlign: 'middle' }} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 0 }}>Simulation History</Typography>
                        </Grid>
                    </Grid>
                </Card>

                <Grid container spacing={3} sx={{ mb: 4 }}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Total Simulations</Typography>
                                <Typography variant="h3">{simulations.length}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Average Score</Typography>
                                <Typography variant="h3">
                                    {simulations.length > 0
                                        ? (simulations.reduce((acc, sim) => acc + sim.scorecard.overallScore, 0) / simulations.length || 0).toFixed(1)
                                        : 0
                                    }%
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Filter Simulations</Typography>
                                <FormControl fullWidth>
                                    <InputLabel>Simulation Type</InputLabel>
                                    <Select
                                        value={filter}
                                        onChange={handleFilterChange}
                                        label="Simulation Type"
                                    >
                                        <MenuItem value="all">All</MenuItem>
                                        <MenuItem value="cold-call">Cold Call</MenuItem>
                                        <MenuItem value="discovery-meeting">Discovery Meeting</MenuItem>
                                    </Select>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                
                <TableContainer component={Paper} elevation={3}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy === 'date'}
                                        direction={orderBy === 'date' ? order : 'asc'}
                                        onClick={() => handleRequestSort('date')}
                                    >
                                        Date
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>Simulation Type</StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={orderBy === 'score'}
                                        direction={orderBy === 'score' ? order : 'asc'}
                                        onClick={() => handleRequestSort('score')}
                                    >
                                        Score
                                    </TableSortLabel>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedSimulations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(simulation => (
                                <StyledTableRow
                                    key={simulation.simulationId}
                                    onClick={() => handleRowClick(simulation.simulationId)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>{new Date(simulation.createdAt).toLocaleDateString()}</TableCell>
                                    <TableCell>{formatSimulationType(simulation.simulationType)}</TableCell>
                                    <TableCell>{simulation.scorecard.overallScore}</TableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredSimulations.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Container>
        </>
    );
}

export default SimulationHistory;