import React, { useState, useEffect, useCallback } from 'react';
import { Grid, CircularProgress, Box, Snackbar, Alert } from "@mui/material";
import { useLocation } from 'react-router-dom'
import { useAuthInfo } from '@propelauth/react'
import Header from './Header';
import RecentSimulations from './RecentSimulations'
import MainContent from './MainContent';
import ProfileSummary from './ProfileSummary';
import ProductSummaryModal from './ProductSummaryModal';
import LoadingScreen from '../Simulation/LoadingScreen';
import UpgradeModal from './UpgradeModal';

function Dashboard() {
    const location = useLocation();
    const [error, setError] = useState(null);
    const [isGeneratingPersona, setIsGeneratingPersona] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [productSummary, setProductSummary] = useState(null);
    const [productSummaryModalOpen, setProductSummaryModalOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
    const [purchaseSuccessOpen, setPurchaseSuccessOpen] = useState(false);
    const authInfo = useAuthInfo();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchDashboardData = useCallback(async () => {
        setIsInitialLoading(true);
        try {
            const [dashboardResponse, summaryResponse, userDataResponse ] = await Promise.all([
                fetch(`${apiBaseUrl}/api/database/dashboard-data/${authInfo.user.userId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    }
                }),
                fetch (`${apiBaseUrl}/api/product-summary/${authInfo.user.userId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    }
                }),
                fetch(`${apiBaseUrl}/api/database/user/${authInfo.user.userId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    }
                })
            ]);

            if (!dashboardResponse.ok || !summaryResponse.ok || !userDataResponse.ok) {
                throw new Error('Failed to fetch data');
            }

            const dashboardData = await dashboardResponse.json();
            const summaryData = await summaryResponse.json();
            const userData = await userDataResponse.json();

            setDashboardData(dashboardData);
            setProductSummary(summaryData);
            setUserData(userData);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setIsInitialLoading(false);
        }
    }, [authInfo.user.userId, apiBaseUrl]);

    useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    useEffect(() => {
        if (location.state?.error) {
            setError(location.state.error);
            window.history.replaceState({}, document.title);
        }
    }, [location]);

    useEffect(() => {
        if (location.state?.purchaseSuccess) {
            setPurchaseSuccessOpen(true);
            fetchDashboardData();
            window.history.replaceState({}, document.title);
        }
    }, [location, fetchDashboardData]);

    const handlePurchaseSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setPurchaseSuccessOpen(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(null);
    };

    const handleEditProfile = () => {
        setProductSummaryModalOpen(true);
    };

    const handleOpenUpgradeModal = useCallback(() => {
        setUpgradeModalOpen(true);
    }, []);
    
    const handleSaveSummary = useCallback(async (summaryData) => {
        setIsUpdating(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/product-summary/${authInfo.user.userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify(summaryData),
            });
            if (!response.ok) {
                throw new Error('Failed to save product summary');
            }
            const data = await response.json();
            setProductSummary(data);
        } catch (error) {
            console.error('Error saving product summary:', error);
        } finally {
            setIsUpdating(false);
            setProductSummaryModalOpen(false);
        }
    }, [authInfo.user.userId, apiBaseUrl]);

    const handlePersonaGenerationStateChange = (isGenerating) => {
        setIsGeneratingPersona(isGenerating);
        if (isGenerating) {
            setIsInitialLoading(true);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Header dashboardData={dashboardData} userData={userData} onOpenUpgradeModal={handleOpenUpgradeModal}/>
            {isGeneratingPersona ? (
                <LoadingScreen />
            ) : isInitialLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3} sx={{ p: 3 }}>
                    <Grid item xs={12} md={3}>
                        <RecentSimulations
                            data={dashboardData?.completedSimulations}
                            isLoading={isGeneratingPersona || isInitialLoading}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MainContent
                            onPersonaGenerationStateChange={handlePersonaGenerationStateChange}
                            dashboardData={dashboardData}
                            isGeneratingPersona={isGeneratingPersona}
                            onEditProfile={handleEditProfile}
                            productSummary={productSummary}
                            onSaveSummary={handleSaveSummary}
                            isUpdating={isUpdating}
                            userData={userData}
                            onOpenUpgradeModal={handleOpenUpgradeModal}
                        />   
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ProfileSummary
                            productSummary={productSummary}
                            onEditProfile={handleEditProfile}
                            isLoading={isGeneratingPersona || isUpdating}
                            userData={userData}
                        />
                    </Grid>
                </Grid>
            )}
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="warning" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            <Snackbar
                open={purchaseSuccessOpen}
                autoHideDuration={4000}
                onClose={handlePurchaseSuccessClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handlePurchaseSuccessClose} severity="success" sx={{ width: '100%' }}>
                    Thank you for your purchase! Your account has been updated.
                </Alert>
            </Snackbar>
            <ProductSummaryModal
                open={productSummaryModalOpen}
                onClose={() => setProductSummaryModalOpen(false)}
                onSave={handleSaveSummary}
                existingSummary={productSummary}
            />
            <UpgradeModal
                open={upgradeModalOpen}
                onClose={() => setUpgradeModalOpen(false)}
                userId={authInfo.user.userId}
            />
        </Box>
    );
}

export default Dashboard;