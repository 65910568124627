import React from 'react';
import { Box, Typography, Card, CardContent, Avatar, Tooltip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupIcon from '@mui/icons-material/Group';

const getScoreColor = (score) => {
    if (score >= 80) return ['#4caf50'];
    if (score >= 60) return ['#ffeb3b'];
    return ['#f44336'];
};

const ScoreCircle = styled(Box)(({ theme, score }) => ({
    width: 120,
    height: 120,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    background: `conic-gradient(
        ${getScoreColor(score)} ${score}%,
        ${theme.palette.grey[300]} ${score}% 100%
    )`,
    position: 'relative',
    '&::after': {
        content: '""',
        position: 'absolute',
        inset: '10px',
        borderRadius: '50%',
        background: theme.palette.background.paper,
    },
}));

const ScoreText = styled(Typography)({
    position: 'relative',
    zIndex: 1,
    fontWeight: 'bold',
});

function ScoreCardHeader({ date, simulationType, sectionScores, summary, userName }) {
    const overallScore = sectionScores.reduce((sum, section) => sum + section.score, 0);
    const maxPossibleScore = sectionScores.length * 10;
    const scorePercentage = (overallScore / maxPossibleScore) * 100;

    return (
        <Card elevation={3} sx={{ mb: 3, overflow: 'visible' }}>
            <CardContent>
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <Avatar sx={{ width: 64, height: 64, bgcolor: 'primary.main' }}>
                            {userName.charAt(0)}
                        </Avatar>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h5" gutterBottom>
                            Personalized {simulationType} Review for {userName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            {new Date(date).toLocaleDateString()} • {simulationType === 'Cold Call' ? <PhoneIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle' }} /> : <GroupIcon fontSize="small" sx={{ ml: 1, verticalAlign: 'middle'}} />}
                        </Typography>
                        <Tooltip title={summary} placement="bottom-start">
                            <Typography variant="body2" sx={{ mt: 1, maxHeight: 60, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {summary}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={`Overall Score: ${overallScore}/${maxPossibleScore}`} placement="left">
                            <ScoreCircle score={scorePercentage}>
                                <ScoreText variant="h4">{Math.round(scorePercentage)}%</ScoreText>
                            </ScoreCircle>
                        </Tooltip>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ScoreCardHeader;