import React from 'react';
import { List, ListItemButton, ListItemIcon, ListItemText, Paper, CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ScoreIcon from '@mui/icons-material/Score';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PersonIcon from '@mui/icons-material/Person';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

const StyledListItemButton = styled(ListItemButton)(({ theme, active }) => ({
    borderLeft: `4px solid ${active ? theme.palette.primary.main : 'transparent'}`,
    backgroundColor: active ? theme.palette.action.selected : 'transparent',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    transition: theme.transitions.create(['background-color', 'border-left'], {
        duration: theme.transitions.duration.shorter,
    }),
}));

function NavigationSidebar({ activeSection, setActiveSection, canShowDetailedAnalysis, canShowTranscript }) {
    const navItems = [
        { name: 'Scorecard', icon: <ScoreIcon /> },
        { name: 'Detailed Analysis', icon: <AnalyticsIcon />, loading: !canShowDetailedAnalysis },
        { name: 'Persona Info', icon: <PersonIcon /> },
        { name: 'Transcript & Audio', icon: <AudiotrackIcon />, loading: !canShowTranscript },
    ];

    return (
        <Paper elevation={2} sx={{ height: '100%', borderRadius: 2, overflow: 'hidden' }}>
            <List disablePadding>
                {navItems.map((item) => (
                    <StyledListItemButton
                        key={item.name}
                        selected={activeSection === item.name}
                        onClick={() => setActiveSection(item.name)}
                        disabled={item.loading}
                    >
                        <ListItemIcon>
                            {item.loading ? (
                                <Box position="relative" display="inline-flex">
                                    <CircularProgress size={24} />
                                    <Box
                                        position="absolute"
                                        top={0}
                                        left={0}
                                        bottom={0}
                                        right={0}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <AnalyticsIcon fontSize="small" color="action" />
                                    </Box>
                                </Box>
                            ) : (
                                item.icon
                            )}
                        </ListItemIcon>
                        <ListItemText primary={item.name} />
                        {item.loading && (
                            <CircularProgress size={16} sx={{ ml: 1 }} />
                        )}
                    </StyledListItemButton>
                ))}
            </List>
        </Paper>
    );
}

export default NavigationSidebar;