import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Tooltip } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupIcon from '@mui/icons-material/Group';

function SimulationTypeModal({ open, onClose, onSelect }) {
    const handleTypeSelection = (type) => {
        onSelect(type);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Choose Simulation Type</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Tooltip title="Practice your cold calling skills with a simulated prospect.">
                            <Button
                                variant="outlined"
                                startIcon={<PhoneIcon />}
                                onClick={() => handleTypeSelection('cold-call')}
                                fullWidth
                            >
                                Cold Call
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Simulate a discovery meeting to uncover prospect needs and pain points.">
                            <Button
                                variant="outlined"
                                startIcon={<GroupIcon />}
                                onClick={() => handleTypeSelection('discovery-meeting')}
                                fullWidth
                            >
                                Discovery Meeting
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Typography variant="body2" style={{ marginTop: '20px' }}>
                    Select the type of simulation you want to practice. Hover over each option for more details.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default SimulationTypeModal;