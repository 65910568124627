import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import PersonaDisplay from './components/Simulation/PersonaDisplay';
import SimulationPage from './components/Simulation/SimulationPage';
import CallReviewPage from './components/CallReview/CallReviewPage';
import SimulationHistory from './components/Dashboard/SimulationHistory';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CheckoutSuccess from './components/Dashboard/CheckoutSuccess';
import analytics from './segmentAnalytics';
import * as Sentry from "@sentry/react";

const theme = createTheme({
  palette: {
    primary: {
      main: '#2853eb',
    },
    secondary: {
      main: '#ff9800',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    let pageTitle = 'Coachable AI';
    switch (location.pathname) {
      case '/':
        pageTitle = 'Dashboard | Coachable AI';
        break;
      case '/persona-display':
        pageTitle = 'Persona Display | Coachable AI';
        break;
      case '/simulation':
        pageTitle = 'Simulation Page | Coachable AI';
        break;
      case '/simulation-history':
        pageTitle = 'Simulation History | Coachable AI';
        break;
      default:
        if (location.pathname.startsWith('/call-review')) {
          pageTitle = 'Call Review | Coachable AI';
        }
        break;
    }

    document.title = pageTitle;

    analytics.page({
      title: pageTitle,
      path: location.pathname,
      url: window.location.href,
    });
  }, [location]);

  return null;
}

function App() {
  useEffect(() => {
    analytics.ready(() => {
      console.log('Segment initialized');
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <PageTracker />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route path="/persona-display" element={<PersonaDisplay />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/simulation" element={<SimulationPage />} />
          <Route path="/call-review/:simulationId" element={<CallReviewPage />} />
          <Route path="/simulation-history" element={<SimulationHistory />} />
          <Route path="/checkout-success" element={<CheckoutSuccess />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);
