import React, {useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, TextField, Paper, CircularProgress, IconButton, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function ProductSummaryReviewModal({ open, onClose, onConfirm, productSummary, onBack, onSaveSummary }) {
    const [editMode, setEditMode] = useState(false);
    const [summary, setSummary] = useState(productSummary?.summary || '');
    const [url, setUrl] = useState(productSummary?.url || '');
    const [isLoading, setIsLoading] = useState(false);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (productSummary) {
            setSummary(productSummary.summary || '');
            setUrl(productSummary.url || '');
        }
    }, [productSummary]);

    const buttonText = editMode ? "Save Product Summary" : "Generate Persona";

    const handleConfirm = async () => {
        if (editMode) {
            await handleSave();
        } else {
            setIsLoading(true);
            await onConfirm({ summary, url });
            setIsLoading(false);
            onClose();
        }
    };

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleSave = async () => {
        setIsLoading(true);
        try {
            await onSaveSummary({ summary, url });
            setEditMode(false);
        } catch (error) {
            console.error('Error saving product summary:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleRegenerateSummary = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/scrape/scrape-summary/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify({ url }),
            });
            if (!response.ok) {
                throw new Error('Failed to generate product summary');
            }
            const data = await response.json();
            setSummary(data.summary);
        } catch (error) {
            console.error('Error generating product summary:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Review Product Summary</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Adjust your company URL to regenerate a summary, edit the existing summary or confirm the details. This will be used to generate your simulation persona.
                </Typography>
                <Paper elevation={3} style={{ padding: '16px', backgroundColor: '#f5f5f5', marginTop: '16px' }}>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={3}>
                            <Typography variant="subtitle2">
                                Company URL:
                            </Typography>
                        </Grid>
                        <Grid item xs={editMode ? 9: 8}>
                            {editMode ? (
                                <TextField
                                    fullWidth
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                />
                            ) : (
                                <Typography variant="body2">
                                    {url || 'No URL provided'}
                                </Typography>
                            )}
                        </Grid>
                        {!editMode && (
                            <Grid item xs={1}>
                                <IconButton
                                    onClick={handleEdit}
                                    color="primary"
                                    size="small"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                    {editMode && (
                        <Box mt={2}>
                            <Button
                                onClick={handleRegenerateSummary}
                                color="primary"
                                disabled={isLoading}
                                variant="outlined"
                                fullWidth
                            >
                                Regenerate Summary
                            </Button>
                        </Box>
                    )}
                    <Box mt={2}>
                        <Typography variant="subtitle2" gutterBottom>
                            Product Summary:
                        </Typography>
                        {editMode ? (
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={summary}
                                onChange={(e) => setSummary(e.target.value)}
                                variant="outlined"
                            />
                        ) : (
                            <Typography variant="body2">
                                {summary || 'No summary available'}
                            </Typography>
                        )}
                    </Box>
                </Paper>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between', padding: '16px' }}>
                <Button onClick={onBack} color="primary">
                    Back
                </Button>
                <Button onClick={handleConfirm} variant="contained" color="primary" disabled={isLoading || !summary.trim()}>
                    {isLoading ? <CircularProgress size={24} /> : buttonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProductSummaryReviewModal;