import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Grid, Typography, Button, Paper, Avatar, Chip, Tabs, Tab, Box, Accordion, AccordionSummary, AccordionDetails, TextField, IconButton, Snackbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import SaveIcon from '@mui/icons-material/Save';
import Header from '../Dashboard/Header';
import { useAuthInfo } from '@propelauth/react';
import LoadingScreen from './LoadingScreen';
import analytics from '../../segmentAnalytics';

function PersonaDisplay() {
    const navigate = useNavigate();
    const location = useLocation();
    const { persona: initialPersona, simulationType, difficulty, summary, userPlan } = location.state || {};
    const [persona, setPersona] = useState(initialPersona);
    const [tabValue, setTabValue] = useState(0);
    const [notes, setNotes] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const authInfo = useAuthInfo();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (!persona) {
            navigate('/');
        }
    }, [persona, navigate]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    };

    const handleSaveNotes = async () => {
        analytics.track('Call Notes Saved', {
            notesSaved: notes,
        });
        try {
            const response = await fetch(`${apiBaseUrl}/api/notes/${persona.simulationId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify({ notes }),
            });

            if (!response.ok) {
                throw new Error('Failed to save notes');
            }

            setSnackbarMessage('Notes saved successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error saving notes:', error);
            setSnackbarMessage('Failed to save notes');
            setSnackbarOpen(true);
        }
    };

    const handleRegeneratePersona = async () => {
        setIsLoading(true);
        analytics.track('Persona Regenerated', {
            simulationId: persona.simulationId,
        });
        try {
            await fetch(`${apiBaseUrl}/api/notes/${persona.simulationId}`, {
                method: 'DELETE',
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            });

            setNotes('');

            const simulationGender = Math.random() < 0.5 ? 'male' : 'female';

            const response = await fetch(`${apiBaseUrl}/api/persona/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify({
                    propelAuthId: authInfo.user.userId,
                    simulationType: simulationType,
                    userEmail: authInfo.user.email,
                    simulationGender: simulationGender,
                    simulationId: persona.simulationId,
                    summary: summary,
                    personaType: 'regenerated'
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to regenerate persona');
            }

            const newPersona = await response.json();
            setPersona(newPersona);
            localStorage.setItem('latestPersona', JSON.stringify(newPersona));
            setSnackbarMessage('Persona regenerated successfully');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error regenerating persona:', error);
            setSnackbarMessage('Failed to regenerate persona');
            setSnackbarOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleStartSimulation = () => {
        analytics.track('Persona Confirmed');
        navigate('/simulation', { state: { persona, simulationType, difficulty, notes, summary, userPlan } });
    };

    const simulationTips = simulationType === 'cold-call'
        ? [
            "Understand the prospect and company beforehand to personalize your approach.",
            "Prepare a strong opening statement to capture attention quickly.",
            "Be prepared to handle common objections.",
            "Focus on scheduling a follow-up meeting rather than making a sale.",
            "Practice active listening and ask open-ended questions."
        ]
        : [
            "Prepare a list of open-ended questions to uncover needs and pain points.",
            "Be ready to discuss your product's features and benefits in context of the prospect's needs.",
            "Listen more than you talk - aim for an 80/20 ratio in favor of the prospect speaking.",
            "Be prepared to handle objections and concerns professionally.",
            "Provide value during the call to build excitement around the next demo call"
        ];

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <Avatar src={persona.imageUrl} sx={{ width: 150, height: 150, mr: 2 }} />
                                <Box>
                                    <Typography variant="h4">{persona.name}</Typography>
                                    <Typography variant="subtitle1" color="text.secondary">{persona.title}</Typography>
                                    <Box sx={{ mt:1 }}>
                                        <Chip icon={<BusinessIcon />} label={persona.industry} sx={{ mr: 1 }} />
                                        <Chip icon={<WorkIcon />} label={`${persona.numberOfEmployees} employees`} />
                                    </Box>
                                </Box>
                            </Box>

                            <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 2 }}>
                                <Tab label="Prospect Details" />
                                <Tab label="Company Info" />
                            </Tabs>

                            {tabValue === 0 && (
                                <Box>
                                    <Typography variant="h6" gutterBottom>Prospect Bio</Typography>
                                    <Typography variant="body1" paragraph>{persona.personaBio}</Typography>
                                </Box>
                            )}

                            {tabValue === 1 && (
                                <Box>
                                    <Typography variant="h6" gutterBottom>Company Details</Typography>
                                    <Typography variant="body1" paragraph>{persona.companyDetails}</Typography>
                                </Box>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h5" gutterBottom>
                                {simulationType === 'cold-call' ? 'Cold Call Simulation' : 'Discovery Meeting Simulation'}
                            </Typography>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Simulation Overview</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1" paragraph>
                                        {simulationType === 'cold-call'
                                        ? "Practice your cold call skills. Prepare to be challenged with capturing the prospect's attention, overcoming objections, and successfully scheduling a meeting for a discovery call."
                                        : "Run a successful discovery meeting. Practice building rapport, discovering your prospect's needs, exhibiting product knowledge, determine if your product is a good fit, overcome objections and successfully schedule a follow-up demo with key stakeholders."}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="h6">Tips for Success</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul>
                                        {simulationTips.map((tip, index) => (
                                            <li key={index}><Typography variant="body2">{tip}</Typography></li>
                                        ))}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>

                            <Box sx={{ mt: 2, mb: 2 }}>
                                <Typography variant="h6" gutterBottom>Preparation Notes</Typography>
                                <TextField
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    value={notes}
                                    onChange={handleNotesChange}
                                    placeholder="Jot down any thoughts or strategies to display during the call"
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                                    <IconButton onClick={handleSaveNotes} color="primary">
                                        <SaveIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box sx={{ mt: 'auto', display: 'flex', justifyContent: 'space-between' }}>
                                <Button onClick={() => navigate('/')} variant="outlined">Back to Dashboard</Button>
                                <Button onClick={handleRegeneratePersona} variant="outlined" color="secondary">Regenerate Persona</Button>
                                <Button onClick={handleStartSimulation} variant="contained" color="primary">Start {simulationType.replace('-', ' ')}</Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
            />
        </>
    );
}

export default PersonaDisplay;