import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Box } from '@mui/material';

function CheckoutSuccess() {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/', { state: { purchaseSuccess: true } });
        }, 3000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
            <CircularProgress />
            <Typography variant="h6" style={{ marginTop: 20 }}>
                Processing your purchase...
            </Typography>
        </Box>
    );
}

export default CheckoutSuccess;