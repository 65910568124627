import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Grid, Card, CardContent, CardActions } from '@mui/material';
import InfiniteIcon from '@mui/icons-material/AllInclusive';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { createCheckoutSession } from '../../services/stripeService';

function UpgradeModal({ open, onClose, userId }) {
    const handleUpgrade = async (plan) => {
        let priceId;
        let isSubscription = false;
        switch (plan) {
            case '5credits':
                priceId = process.env.REACT_APP_STRIPE_5CREDIT_PRICE_ID;
                break;
            case '10credits':
                priceId = process.env.REACT_APP_STRIPE_10CREDIT_PRICE_ID;
                break;
            case 'unlimited':
                priceId = process.env.REACT_APP_STRIPE_UNLIMITED_PRICE_ID;
                isSubscription = true;
                break;
            default:
                console.error('Invalid plan selected');
                return;
        }

        try {
            await createCheckoutSession(priceId, userId, isSubscription);
        } catch (error) {
            console.error('Error creating checkout session:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Choose Your Plan</DialogTitle>
            <DialogContent>
                <Typography variant="body1" paragraph>
                    Continue improving your sales skills with more AI-powered simulations. Choose the plan that works best for you:
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    5 Credit Package
                                </Typography>
                                <CreditCardIcon fontSize="large" color="primary" />
                                <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                                    $9 <small>one-time</small>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    5 additional simulation credits
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant="outlined" color="primary" onClick={() => handleUpgrade('5credits')}>Select</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    10 Credit Package
                                </Typography>
                                <CreditCardIcon fontSize="large" color="primary" />
                                <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                                    $15 <small>one-time</small>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    10 additional simulation credits
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant="outlined" color="primary" onClick={() => handleUpgrade('10credits')}>Select</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined" sx={{ border: 2, borderColor: 'primary.main' }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom color="primary">
                                    Unlimited Plan
                                </Typography>
                                <InfiniteIcon fontSize="large" color="primary" />
                                <Typography variant="h5" component="div" sx={{ mt: 2 }}>
                                    $25 <small>billed monthly</small>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Unlimited credits, cancel anytime
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant="contained" color="primary" onClick={() => handleUpgrade('unlimited')} sx={{ fontWeight: 'bold' }}>Choose Unlimited</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>

                <Box sx={{ mt: 3 }}>
                    <Typography variant="body2">
                        Need help deciding? Contact us at {' '}
                        <a href="mailto:support@joincoachable.ai">support@joincoachable.ai</a>
                        {' '}or{' '}
                        <a href="https://calendly.com/justin-coachable/intro-call" target="_blank" rel="noopener noreferrer">
                            schedule a call
                        </a>
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UpgradeModal;