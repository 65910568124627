import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Tooltip } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import WorkIcon from '@mui/icons-material/Work';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function DifficultySelectionModal({ open, onClose, onSelect, onBack }) {
    const difficulties = [
        { level: 'Beginner', icon: SchoolIcon, description: 'For those new to sales or looking to practice the basics in simulations.' },
        { level: 'Intermediate', icon: WorkIcon, description: 'For experienced sales professionals wanting to refine their skills in a more challenging environment.' },
        { level: 'Advanced', icon: EmojiEventsIcon, description: 'For seasoned pros looking for the ultimate challenge to enhance your skills.' }
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Choose Difficulty Level</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} justifyContent="center">
                    {difficulties.map(({ level, icon: Icon, description }) => (
                        <Grid item xs={12} sm={4} key={level}>
                            <Tooltip title={description} placement="top">
                                <Button
                                    variant="outlined"
                                    startIcon={<Icon />}
                                    onClick={() => onSelect(level.toLowerCase())}
                                    fullWidth
                                    sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 2 }}
                                >
                                    <Typography variant="button">{level}</Typography>
                                </Button>
                            </Tooltip>
                        </Grid>
                    ))}
                </Grid>
                <Typography variant="body2" style={{ marginTop: '20px' }}>
                    Select the difficulty level for your simulation. Hover over each option for more details.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack}>Back</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DifficultySelectionModal