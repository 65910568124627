import React from 'react';
import { Box, Typography, Grid, Paper, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const ScoreCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
}));

const ScoreItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const ScoreValue= styled(Typography)(({ theme, score }) => ({
    fontWeight: 'bold',
    color: score >= 8 ? theme.palette.success.main : score >= 6 ? theme.palette.warning.main : theme.palette.error.main,
}));

const CategoryTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.text.primary,
}));

function Scorecard({ scorecard }) {
    if (!scorecard) {
        return <Typography>No scorecard data available</Typography>;
    }

    return (
        <ScoreCard elevation={3}>
            <Typography variant="h5" gutterBottom>Performance Scorecard</Typography>
            <Divider sx={{ mb: 1 }} />
            <Grid container spacing={1}>
                {scorecard.initialAnalysis.sections.map((section, index) => (
                    <Grid item xs={12} key={index}>
                        <ScoreItem>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={3}>
                                    <CategoryTitle variant="subtitle1">
                                        {section.title}
                                    </CategoryTitle>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <ScoreValue variant="h6" score={section.score}>
                                        {section.score} / 10
                                    </ScoreValue>
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    <Typography variant="body2" color="text.secondary">
                                        {section.justification}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ScoreItem>
                        {index < scorecard.initialAnalysis.sections.length - 1 && (
                            <Divider sx={{ my: 0.5 }} />
                        )}
                    </Grid>
                ))}
            </Grid>
        </ScoreCard>
    );
}

export default Scorecard;