import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlagIcon from '@mui/icons-material/Flag';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MicIcon from '@mui/icons-material/Mic';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmailIcon from '@mui/icons-material/Email';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

function HelpDialog({ open, onClose, simulationType }) {
    const objectiveContent = simulationType === 'cold-call'
        ? "Schedule a follow-up meeting with the prospect."
        : "Understand the prospect's needs and schedule a demo call";

    const tipsContent = simulationType === 'cold-call'
        ? ["Be concise and confident", "Listen actively", "Handle objections smoothly", "Focus on value proposition"]
        : ["Ask open-ended questions", "Understand client needs", "Provide value propositions", "Build rapport"];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                Simulation Help Guide
            </DialogTitle>
            <DialogContent dividers>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <FlagIcon sx={{ mr: 1 }} /> Call Objective
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{objectiveContent}</Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <LightbulbIcon sx={{ mr: 1 }} /> Tips
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List dense>
                            {tipsContent.map((tip, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <CheckCircleOutlineIcon color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={tip} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormatListNumberedIcon sx={{ mr: 1 }} /> Steps to a Successful Call
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            <ListItem>
                                <ListItemIcon><PlayArrowIcon color="primary" /></ListItemIcon>
                                <ListItemText primary="Click the 'Start Call' button" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><MicIcon color="primary" /></ListItemIcon>
                                <ListItemText primary="Enable microphone permissions" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><VolumeUpIcon color="primary" /></ListItemIcon>
                                <ListItemText primary="Adjust volume to your preference" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><PersonIcon color="primary" /></ListItemIcon>
                                <ListItemText primary="The prospect will start the call by speaking" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><CheckCircleOutlineIcon color="primary" /></ListItemIcon>
                                <ListItemText primary="Complete a successful Call" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><AssessmentIcon color="primary" /></ListItemIcon>
                                <ListItemText primary="Click 'End Call' button to analyze and receive your call revie" />"
                            </ListItem>
                        </List>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                        <HelpOutlineIcon sx={{ mr: 1 }} /> Questions/Support
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Any issues or questions? Reach out to us at:
                        </Typography>
                        <Typography sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <EmailIcon sx={{ mr: 1 }} color="primary" />
                            support@joincoachable.ai
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default HelpDialog;