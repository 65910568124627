import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Tooltip, Box } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

function PersonaGenerationMethodModal({ open, onClose, onBack, onSelect, simulationType }) {
    const handleMethodSelection = (method) => {
        onSelect(method);
    };

    const buttonStyle = {
        height: '80px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Choose Persona Generation Method</DialogTitle>
            <DialogContent>
                <Typography variant = "subtitle1" gutterBottom>
                    {simulationType === 'cold-call' ? 'Cold Call' : 'Discovery Meeting'} Simulation
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Tooltip title="Our AI Persona Engine will automatically generate a persona based on your product summary.">
                            <Button
                                variant="outlined"
                                onClick={() => handleMethodSelection('ai-generated')}
                                fullWidth
                                style={buttonStyle}
                            >
                                <AutoFixHighIcon />
                                <Box mt={1}>AI-Generated</Box>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Tooltip title="Create a persona based on a specific person's LinkedIn profile. (Coming Soon!)">
                            <span>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    style={buttonStyle}
                                    disabled
                                >
                                    <PersonIcon />
                                    <Box mt={1}>Specific Person</Box>
                                    <Typography variant="caption" color="textSecondary">Coming Soon</Typography>
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Tooltip title="Create a persona based on a specific company's profile. (Coming Soon!)">
                            <span>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    style={buttonStyle}
                                    disabled
                                >
                                    <BusinessIcon />
                                    <Box mt={1}>
                                        <Typography variant="body2">Specific Company</Typography>
                                        <Typography variant="caption" color="textSecondary">Coming Soon</Typography>
                                    </Box>
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Typography variant="body2" style={{ marginTop: '20px' }}>
                    Select how you want to generate the persona for your simulation. Hover over each option for more details.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onBack}>Back</Button>
                <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default PersonaGenerationMethodModal;