import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Button, Box, Avatar, CircularProgress, Grid, Divider } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupIcon from '@mui/icons-material/Group';
import { styled } from '@mui/material/styles';

const ScoreAvatar = styled(Avatar)(({ theme, score }) => ({
    backgroundColor: score >= 80 ? theme.palette.success.main : score >= 60 ? theme.palette.warning.main : theme.palette.error.main,
    color: theme.palette.common.white,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: '0.8rem',
    fontWeight: 'bold',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    padding: theme.spacing(1, 2),
}));

function RecentSimulations({ data, isLoading }) {
    const navigate = useNavigate();

    const handleReviewClick = (simulationId) => {
        navigate(`/call-review/${simulationId}`);
    };

    const getSimulationIcon = (type) => {
        return type === 'cold-call' ? <PhoneIcon /> : <GroupIcon />;
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    if(isLoading) {
        return (
            <Card elevation={3}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Recent Simulations</Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" height={200}>
                        <CircularProgress />
                    </Box>
                </CardContent>
            </Card>
        );
    }

    const sortedData = data ? [...data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];

    return (
        <Card elevation={3}>
            <CardContent>
                <Typography variant="h6">Recent Simulations</Typography>
                {sortedData && sortedData.length > 0 ? (
                    <>
                        <List disablePadding>
                            {sortedData.slice(0, 5).map((simulation) => (
                                <StyledListItem key={simulation.simulationId}>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Avatar sx={{ bgcolor: 'primary.main' }}>
                                                {getSimulationIcon(simulation.simulationType)}
                                            </Avatar>
                                        </Grid>
                                        <Grid item xs>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="subtitle2">
                                                        {simulation.simulationType === 'cold-call' ? 'Cold Call' : 'Discovery Meeting'}
                                                    </Typography>
                                                }
                                                secondary={
                                                    <Typography variant="caption">
                                                        {formatDate(simulation.createdAt)}
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <ScoreAvatar score={simulation.scorecard.overallScore}>
                                                {simulation.scorecard.overallScore}%
                                            </ScoreAvatar>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                onClick={() => handleReviewClick(simulation.simulationId)}
                                                variant="outlined"
                                                size="small"
                                            >
                                                Review Details
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </StyledListItem>
                            ))}
                        </List>
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Box display="flex" justifyContent="center">
                            <Button
                                component={RouterLink}
                                to="/simulation-history"
                                variant="text"
                                color="primary"
                                sx={{ fontWeight: 'bold' }}
                            >
                                View All Simulations
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" py={4}>
                        <Typography variant="body1" gutterBottom>
                            No completed simulations yet.
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Complete your first simulation to view your scorecards here!
                        </Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export default RecentSimulations;
