const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const deductCredit = async (userId) => {
    try {
        const response = await fetch(`${ApiBaseUrl}/api/credits/deduct/${userId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': 'true',
            },
        });

        if (!response.ok) {
            throw new Error('Failed to deduct credit');
        }

        return response.json()
    } catch (error) {
        console.error('Error deducting credit:', error);
        throw error;
    }
};