import React from 'react';
import { Paper, Typography, Button, Box } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function PreCallDisplay({ persona, simulationType, onStartCall, onHelp }) {
    return (
        <Paper elevation={3} sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItem: 'center', mb: 2 }}>
                <VolumeUpIcon sx={{ mr: 1}} />
                <Typography variant="body1">
                    Make sure to turn on your volume and allow microphone permissions when prompted after starting the call.
                </Typography>
            </Box>
            <Typography variant="body1" align="center" paragraph>
                Click 'Start Call' when you're ready to begin your {simulationType === "cold-call" ? "Cold Call" : "Discovery Meeting"} with {persona.name}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={onStartCall}
                sx={{ mb: 2 }}
            >
                Start Call
            </Button>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<HelpOutlineIcon />}
                onClick={onHelp}
            >
                Help
            </Button>
        </Paper>
    );
}

export default PreCallDisplay;