import React from 'react';
import { Paper, Button, Tooltip } from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function ControlBar({ isMuted, onToggleMute, onEndCall, onHelp }) {
    return (
        <Paper
            elevation={3}
            sx={{
                p:2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 3
            }}
        >
            <Tooltip title={isMuted ? "Unmute" : "Mute"}>
                <Button 
                    variant="contained"
                    color={isMuted ? "error" : "primary"}
                    onClick={onToggleMute}
                    startIcon={isMuted ? <MicOffIcon /> : <MicIcon />}
                    size="large"
                >
                    {isMuted ? "Unmute" : "Mute"}
                </Button>
            </Tooltip>
            <Tooltip title="End Call">
                <Button
                    variant="contained"
                    color="error"
                    onClick={onEndCall}
                    startIcon={<CallEndIcon />}
                    size="large"
                >
                    End Call
                </Button>
            </Tooltip>
            <Tooltip title="Help">
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={onHelp}
                    startIcon={<HelpOutlineIcon />}
                    size="large"
                >
                    Help
                </Button>
            </Tooltip>
        </Paper>
    );
}

export default ControlBar;