import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Grid, Box, LinearProgress, Tooltip } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import InfoIcon from '@mui/icons-material/Info';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ChartTooltip,
    Legend
);

function PerformanceOverview({ data }) {
    const performanceData = useMemo(() => {
        if (!data || !Array.isArray(data) || data.length === 0) {
            return null;
        }

        const validSimulations = data
        .filter(sim => sim?.scorecard?.overallScore != null)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        const totalSimulations = validSimulations.length; // Only includes simulations with a scorecard to show completion

        if (totalSimulations === 0) {
            return null;
        }
        const allScores = validSimulations.map(sim => sim.scorecard.overallScore);
        const averageScore = allScores.reduce((a, b) => a + b, 0) / totalSimulations;

        const recentScores = allScores.slice(0, 5).reverse();
        const recentAverage = recentScores.length > 0 ? recentScores.reduce((a, b) => a + b, 0) / recentScores.length : 0;
        const improvement = averageScore !== 0 ? ((recentAverage - averageScore) / averageScore) * 100 : 0;

        const categories = validSimulations[0]?.scorecard?.initialAnalysis?.sections?.map(section => section.title) || [];
        const categoryScores = categories.map(category => 
            validSimulations.reduce((sum, sim) =>
                sum + (sim.scorecard?.initialAnalysis?.sections?.find(s => s.title === category)?.score || 0), 0
            ) / totalSimulations
        );

        const topStrengthIndex = categoryScores.length > 0 ? categoryScores.indexOf(Math.max(...categoryScores)) : -1;
        const improvementAreaIndex = categoryScores.length > 0 ? categoryScores.indexOf(Math.min(...categoryScores)) : -1;

        return {
            totalSimulations,
            averageScore: Math.round(averageScore),
            improvement: Math.round(improvement * 10) / 10,
            topStrength: topStrengthIndex !== -1 ? categories[topStrengthIndex] : 'N/A',
            areaForImprovement: improvementAreaIndex !== -1 ? categories[improvementAreaIndex] : 'N/A',
            recentScores: recentScores,
            recentScoresCount: recentScores.length
        };
    }, [data]);

    if (!performanceData) {
        return <Typography>No performance data available</Typography>
    }

    const MetricWithTooltip = ({ title, value, tooltip }) => (
        <Box>
            <Typography variant="h6" display="flex" alignItems="center">
                {title}
                <Tooltip title={tooltip} arrow placement="top">
                    <InfoIcon fontSize="small" sx={{ ml: 1, cursor: 'help' }} />
                </Tooltip>
            </Typography>
            <Typography variant="h4">{value}</Typography>
        </Box>
    );

    const chartData = {
        labels: performanceData.recentScores.map((_, index) => {
            const number = performanceData.recentScoresCount - index;
            let suffix = number === 1 ? 'st' : number === 2 ? 'nd' : number === 3 ? 'rd' : 'th';
            return `${number}${suffix} Last`;
        }),
        datasets: [{
            label: 'Recent Scores',
            data: performanceData.recentScores,
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
        }]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'category',
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                ticks: {
                    callback: function(value) {
                        return value + '%'
                    }
                }
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        return 'Recent Score: ' + context.parsed.y + '%';
                    }
                }
            }
        }
    };

    return (
        <Card elevation={3}>
            <CardContent>
                <Typography variant="h5" gutterBottom>Performance Overview</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MetricWithTooltip
                            title="Total Simulations Completed"
                            value={performanceData.totalSimulations}
                            tooltip="The total number of completed sales simulations all-time."
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MetricWithTooltip
                            title="Average Score"
                            value={`${performanceData.averageScore}%`}
                            tooltip="Your average score across all-time completed simulations. The percentage change shows your most recent 5 completed simulations compared to all-time performance."
                        />
                        <Box display="flex" alignItems="center">
                            <Box ml={1} display="flex" alignItems="center" color={performanceData.improvement >= 0 ? "success.main" : "error.main"}>
                                {performanceData.improvement >= 0 ? <TrendingUpIcon /> : <TrendingDownIcon />}
                                <Typography variant="body2">{Math.abs(performanceData.improvement)}%</Typography>
                            </Box>
                        </Box>
                        <LinearProgress 
                            variant="determinate" 
                            value={performanceData.averageScore} 
                            sx={{ mt: 1, height: 10, borderRadius: 5 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MetricWithTooltip
                            title="Top Strength"
                            value={performanceData.topStrength}
                            tooltip="Your highest performing category across all-time simulations completed"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MetricWithTooltip
                            title="Area for Improvement"
                            value={performanceData.areaForImprovement}
                            tooltip="Your category with the biggest opportunity for improvement across all-time simulations completed"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Recent Performance</Typography>
                        <Box height={200}>
                            <Line data={chartData} options={ chartOptions } />
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default PerformanceOverview;