import React from 'react';
import { Paper, Typography, Divider } from '@mui/material';

function CallInfoPanel({ duration, notes, simulationType }) {
    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>
                {simulationType === "cold-call" ? "Cold Call" : "Discovery Meeting"} Objective
            </Typography>
            <Typography variant="body2" paragraph>
                {simulationType === "cold-call"
                    ? "Schedule a follow-up meeting to explore how your product can address the prospect's needs."
                    : "Understand the prospect's needs in depth and schedule a demo call with key stakeholders."}
            </Typography>
            <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1">
                    Duration: {formatDuration(duration)}
                </Typography>
            {notes && (
                <>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle1" gutterBottom>
                        Your Pre-Call Notes:
                    </Typography>
                    <Typography variant="body2" sx={{ flex: 1, overflowY: 'auto' }}>
                        {notes}
                    </Typography>
                </>
            )}
        </Paper>
    );
}

export default CallInfoPanel;