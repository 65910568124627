import {useState, useEffect, useCallback, useRef } from 'react';

const POLL_INTERVAL = 5000;
const MAX_POLL_ATTEMPTS = 12;
const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const useCallReviewData = (simulationId, initialData) => {
    const [data, setData] = useState(initialData || {});
    const [isLoading, setIsLoading] = useState(!initialData);
    const [isUpdating, setIsUpdating] = useState(false);
    const [error, setError] = useState(null);
    const pollAttemptsRef = useRef(0);
    const pollTimeoutRef = useRef(null);

    const fetchData = useCallback(async () => {
        if (!simulationId) return;

        try {
            const response = await fetch(`${ApiBaseUrl}/api/database/call-review-data/${simulationId}`, {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                }
            });
            if (!response.ok) throw new Error('Failed to fetch call review data');
            const fetchedData = await response.json();
            setData(prevData => ({
                ...prevData,
                ...fetchedData,
                scorecard: {
                    ...prevData.scorecard,
                    ...fetchedData.scorecard
                }
            }));
            return fetchedData;
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
            setIsUpdating(false);
        }
    }, [simulationId]);

    const pollForData = useCallback(async () => {
        setIsUpdating(true);
        const fetchedData = await fetchData();
        if (fetchedData?.scorecard?.detailedAnalysis || pollAttemptsRef.current >= MAX_POLL_ATTEMPTS) {
            setIsUpdating(false);
        } else {
            pollAttemptsRef.current += 1;
            pollTimeoutRef.current = setTimeout(pollForData, POLL_INTERVAL);
        }
    }, [fetchData]);

    useEffect(() => {
        if (!initialData?.scorecard?.detailedAnalysis) {
            pollForData();
        }

        return () => {
            if (pollTimeoutRef.current) {
                clearTimeout(pollTimeoutRef.current);
            }
        };
    }, [pollForData, initialData]);

    return { data, isLoading, isUpdating, error };
};

export default useCallReviewData;