import React, { useState} from 'react';
import { Box, Typography, Paper, Avatar, Chip, Grid, Card, CardContent, IconButton, Collapse } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other } />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function PersonaInfo({ persona }) {
    const [expandedBio, setExpandedBio] = useState(false);
    const [expandedCompany, setExpandedCompany] = useState(false);

    if (!persona) {
        return <Typography>No persona information available</Typography>
    }

    return (
        <Paper elevation={3} sx={{ p: 3, backgroundColor: '#f5f5f5' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center">
                                <Avatar src={persona.imageUrl} sx={{ width: 100, height: 100, mr: 2 }} />
                                <Box>
                                    <Typography variant="h5">{persona.name}</Typography>
                                    <Typography variant="subtitle1" color="text.secondary">{persona.title}</Typography>
                                    <Box sx={{ mt: 1}}>
                                        <Chip icon={<BusinessIcon />} label={persona.industry} sx={{ mr: 1 }} />
                                        <Chip icon={<WorkIcon />} label={`${persona.numberOfEmployees} employees`} />
                                    </Box>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6">Persona Bio</Typography>
                                <ExpandMore
                                    expand={expandedBio}
                                    onClick={() => setExpandedBio(!expandedBio)}
                                    aria-expanded={expandedBio}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Box>
                            <Collapse in={expandedBio} timeout="auto" unmountOnExit>
                                <Typography variant="body2" sx={{ mt: 2 }}>{persona.personaBio}</Typography>
                            </Collapse>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h6">Company Details</Typography>
                                <ExpandMore
                                    expand={expandedCompany}
                                    onClick={() => setExpandedCompany(!expandedCompany)}
                                    aria-expanded={expandedCompany}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </ExpandMore>
                            </Box>
                            <Collapse in={expandedCompany} timeout="auto" unmountOnExit>
                                <Typography variant="body2" sx={{ mt: 2 }}>{persona.companyDetails}</Typography>
                            </Collapse>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Pain Points</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {persona.industryPainPoints.map((point, index) => (
                                    <Chip key={index} label={point} variant="outlined" color="primary" />
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>Objections</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {persona.potentialObjections.map((objection, index) => (
                                    <Chip key={index} icon={<ErrorOutlineIcon />} label={objection} variant="outlined" color="secondary" />
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PersonaInfo;